import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import Button, { type ButtonProps } from '@atlaskit/button';
import { MODAL_ID } from '@atlassian/jira-atlassian-navigation/src/common/constants.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { createProjectAction_projectsDirectoryV3_CreateButton$key } from '@atlassian/jira-relay/src/__generated__/createProjectAction_projectsDirectoryV3_CreateButton.graphql';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller/src/index.tsx';
import messages from './messages.tsx';

type Props = {
	dataRef: createProjectAction_projectsDirectoryV3_CreateButton$key;
};

const CreateButtonComponent = (props: ButtonProps) => {
	const { formatMessage } = useIntl();

	return (
		<Button appearance="primary" {...props}>
			{formatMessage(messages.createProject)}
		</Button>
	);
};

const CreateButton = ({ dataRef }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'directoryHeader',
	});

	const data = useFragment<createProjectAction_projectsDirectoryV3_CreateButton$key>(
		graphql`
			fragment createProjectAction_projectsDirectoryV3_CreateButton on JiraQuery {
				canCreateProject: canPerform(type: CREATE_PROJECT, cloudId: $cloudId)
					@optIn(to: "JiraAction")
			}
		`,
		dataRef,
	);

	const [, { on: openProjectCreateDrawer }] = useSwitchModals(MODAL_ID.PROJECT_CREATE);

	const handleCreate = useCallback(() => {
		openProjectCreateDrawer();
		fireUIAnalytics(analyticsEvent, 'CreateProjectButton');
	}, [analyticsEvent, openProjectCreateDrawer]);

	if (!data?.canCreateProject) {
		return null;
	}

	return (
		<CreateButtonComponent
			data-testId="global-pages.directories.projects-directory-v3.create-projects-button"
			onClick={handleCreate}
		/>
	);
};

const CreateButtonContainer = (props: Props) => (
	<JSErrorBoundary
		id="create-project-button"
		packageName="jira-project-directory-page-v3"
		fallback="flag"
	>
		<CreateButton {...props} />
	</JSErrorBoundary>
);

export { CreateButtonContainer as CreateButton };
