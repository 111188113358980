import React, { Component } from 'react';
import UFOCustomData from '@atlaskit/react-ufo/custom-data';
import { UFOBM3TimingsToUFO } from '@atlaskit/react-ufo/custom-timings';
import { getInteractionId } from '@atlaskit/react-ufo/interaction-id-context';
import { GlobalPageLoadExperience } from '@atlassian/ufo';
import { StopBrowserMetrics3 } from './stop-bm3/index.tsx';
import { StopUfo } from './stop-ufo/index.tsx';
import type { BM3Metric, BM3Config, CustomData } from './types.tsx';
import { addBM3SSRDoneAsFMPToInteraction } from './utils/add-bm3-config-to-interaction.tsx';
import { addUFOSSRDoneAsFMPToInteraction } from './utils/add-ufo-config-to-interaction.tsx';
import { trackBM3FeatureFlagsAccessed } from './utils/track-bm3-feature-flags-accessed.tsx';

export type Props = {
	extra: CustomData;
	appName?: string;
	metricKey?: string;
	metric?: BM3Metric;
	emitOnAnimationFrame?: Boolean;
};

export const checkAndEmitApdexSignal = (appName?: string, metricKey?: string) => {
	if (process.env.NODE_ENV !== 'production') {
		// eslint-disable-next-line jira/import/webpack-magic-comment-entries
		import('@atlassian/jira-common-integration-tests/src/signal/emit').then(({ emitSignal }) =>
			emitSignal('apdex.submited', {
				appName,
				metricKey,
			}),
		);
	}
};

// Props, defaultProps and checkAndEmitApdexSignal are consumed in ../apdex-actions
// Changes here should be duplicated there
// eslint-disable-next-line jira/react/no-class-components
export default class SubmitApdexMark extends Component<Props> {
	static defaultProps = {
		isExpectedToHaveSsr: false,
		withMarks: [],
		extra: {},
		emitOnAnimationFrame: false,
	};

	render() {
		const { appName, metricKey, extra, metric, emitOnAnimationFrame } = this.props;

		checkAndEmitApdexSignal(appName, metricKey);

		const isUFODefined = GlobalPageLoadExperience.loadingPageLoadId !== 'UNKNOWN';

		if (isUFODefined) {
			addUFOSSRDoneAsFMPToInteraction();
		}
		if (metric) {
			// https://product-fabric.atlassian.net/browse/UFO-323
			addBM3SSRDoneAsFMPToInteraction(metric);
			trackBM3FeatureFlagsAccessed(metric);
		}

		return (
			<>
				<UFOCustomData data={extra} />
				<UFOBM3TimingsToUFO
					marks={metric?.getData()?.marks}
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					timings={(metric?.getData()?.config as BM3Config)?.timings}
				/>
				{isUFODefined ? (
					<StopUfo appName={appName} customData={extra} key={getInteractionId().current} />
				) : (
					metric && (
						<StopBrowserMetrics3
							metric={metric}
							customData={extra}
							emitOnAnimationFrame={emitOnAnimationFrame}
						/>
					)
				)}
			</>
		);
	}
}

export const baseDefaultProps = SubmitApdexMark.defaultProps;
