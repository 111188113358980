import React, { type ReactNode, useCallback } from 'react';
import { styled } from '@compiled/react';
import ArrowDownIcon from '@atlaskit/icon/core/migration/arrow-down';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fontSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	type AriaSortType,
	SortOrder,
	SortOrderLabel,
	type SortProps,
	type AriaLabelType,
} from '../../../types.tsx';
import messages from './messages.tsx';

export interface HeadCellProps extends SortProps {
	key: string;
	cellKey: string;
	content: ReactNode;
	isSortable?: boolean;
	width?: number;
	tooltipContent?: ReactNode;
	testId?: string;
	textAlign?: string;
	isIconColumn?: boolean;
	element?: 'th' | 'td';
}

interface TooltipContentProps {
	sortOrder: SortProps['sortOrder'];
	children: ReactNode;
}

const TooltipContent = ({ children, sortOrder }: TooltipContentProps) => {
	const { formatMessage } = useIntl();

	return (
		<TooltipContentWrapper>
			{children} •{' '}
			{formatMessage(
				sortOrder === SortOrder.ASC
					? messages.tooltipAscSortingDirectionContent
					: messages.tooltipDescSortingDirectionContent,
			)}
		</TooltipContentWrapper>
	);
};

interface TooltipWrapperProps
	extends Pick<HeadCellProps, 'tooltipContent' | 'content' | 'sortOrder'> {
	children: JSX.Element;
}

const TooltipWrapper = ({ tooltipContent, content, sortOrder, children }: TooltipWrapperProps) => {
	if (__SERVER__) {
		return children;
	}

	return (
		<Tooltip
			content={tooltipContent || <TooltipContent sortOrder={sortOrder}>{content}</TooltipContent>}
			position="top"
		>
			{children}
		</Tooltip>
	);
};

export const SortableIconCellContentWrapperSelector =
	'simple-table-sortable-icon-cell-content-wrapper';

export const HeadCell = ({
	isSortable,
	cellKey,
	content,
	sortKey,
	sortOrder,
	tooltipContent,
	onSort,
	width,
	textAlign,
	isIconColumn,
	element,
}: HeadCellProps) => {
	const isActive = cellKey === sortKey;
	const { formatMessage } = useIntl();

	const handleClick = useCallback(
		() =>
			onSort({
				key: cellKey,
				sortOrder: isActive && sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
			}),
		[cellKey, sortOrder, onSort, isActive],
	);

	let sortableValue: AriaSortType;
	if (isSortable) {
		sortableValue = 'none';
		if (isActive && sortOrder) {
			sortableValue = SortOrderLabel[sortOrder];
		}
	}

	const buttonProperties = {
		'aria-roledescription': isSortable
			? formatMessage(messages.a11YSortButtonDescriptionLabel)
			: undefined,
	};

	const wrappedContent = isIconColumn ? (
		<IconCellContentWrapper
			data-component-selector={isSortable ? SortableIconCellContentWrapperSelector : ''}
			isSortable={isSortable}
			isActive={isActive}
		>
			{content}
		</IconCellContentWrapper>
	) : (
		content
	);

	return (
		<HeadCellContainer
			as={element}
			isSortable={isSortable}
			width={width}
			aria-sort={sortableValue}
			textAlign={textAlign}
			isIconColumn={isIconColumn}
		>
			{isSortable ? (
				<TooltipWrapper tooltipContent={tooltipContent} content={content} sortOrder={sortOrder}>
					<SortHeadCellContainer
						width={width}
						isActive={isActive}
						onClick={handleClick}
						isIconColumn={isIconColumn}
						isVisualRefresh={isVisualRefreshEnabled()}
						{...buttonProperties}
					>
						{wrappedContent}
						<SortIconContainer
							isActive={isActive}
							sortOrder={sortOrder}
							isIconColumn={isIconColumn}
						>
							<ArrowDownIcon
								LEGACY_size="small"
								label=""
								color={token('color.text.subtle')}
								LEGACY_primaryColor={token('color.icon')}
							/>
						</SortIconContainer>
					</SortHeadCellContainer>
				</TooltipWrapper>
			) : (
				wrappedContent
			)}
		</HeadCellContainer>
	);
};

interface SortIconContainerProps {
	isActive?: boolean;
	isIconColumn?: boolean;
	sortOrder?: SortOrder;
	'data-component-selector'?: string;
}

const SortIconContainerSelector = 'simple-table-sort-icon-container';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SortIconContainer = styled.div<SortIconContainerProps>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: (props) => (props.isActive ? '1 !important' : 0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: (props) => (props.isActive || props.isIconColumn ? token('space.050', '4px') : 0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: (props) => (props.isIconColumn ? token('space.050', '4px') : 0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	transform: (props) => (props.sortOrder === SortOrder.DESC ? 'rotate(180deg)' : 'none'),
	transition: 'all .15s ease-in-out',
});

SortIconContainer.defaultProps = {
	'data-component-selector': SortIconContainerSelector,
};

interface IconCellContentWrapperProps {
	isSortable?: boolean;
	isActive?: boolean;
	'data-component-selector'?: string;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconCellContentWrapper = styled.div<IconCellContentWrapperProps>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	position: (props) => (props.isSortable ? 'absolute' : 'unset'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: (props) => (props.isActive ? '0 !important' : 1),
	top: '50%',
	left: '50%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: (props) => (props.isSortable ? 'translate(-50%, -50%)' : 'none'),
	transition: 'opacity .15s ease-in-out',
});

interface HeadCellContainerProps {
	width?: number;
	isSortable?: boolean;
	textAlign?: string;
	isIconColumn?: boolean;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeadCellContainerOld = styled.th<HeadCellContainerProps & AriaLabelType>({
	display: 'table-cell',
	position: 'relative',
	appearance: 'none',
	verticalAlign: 'middle',
	borderColor: 'initial',
	borderImage: 'initial',
	borderStyle: 'none',
	borderWidth: 'initial',
	boxSizing: 'border-box',
	fontWeight: token('font.weight.medium'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSize}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N500),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: (props) =>
		props.isSortable || (props.isIconColumn && props.textAlign === 'center')
			? 0
			: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: (props) =>
		props.isIconColumn && props.textAlign === 'center' ? 0 : token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => (props.width ? `${props.width}%` : '100%'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	textAlign: (props) => (props.textAlign ? `${props.textAlign}` : undefined),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before': {
		position: 'absolute',
		boxShadow: `inset 0 -2px 0 0 ${token('color.border', '#eee')}`,
		content: '',
		inset: 0,
		pointerEvents: 'none',
		bottom: 0,
		top: 0,
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-of-type': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		paddingLeft: (props) =>
			props.isSortable || (props.isIconColumn && props.textAlign === 'center')
				? 0
				: token('space.100', '8px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-of-type': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		paddingRight: (props) =>
			props.isIconColumn && props.textAlign === 'center' ? 0 : token('space.100', '8px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeadCellContainerNew = styled.th<HeadCellContainerProps & AriaLabelType>({
	display: 'table-cell',
	position: 'relative',
	appearance: 'none',
	verticalAlign: 'middle',
	borderColor: 'initial',
	borderImage: 'initial',
	borderStyle: 'none',
	borderWidth: 'initial',
	boxSizing: 'border-box',
	borderBottom: `1px solid ${token('color.border')}`,
	font: token('font.heading.xxsmall'),
	color: token('color.text.subtle'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: (props) =>
		props.isSortable || (props.isIconColumn && props.textAlign === 'center')
			? 0
			: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: (props) =>
		props.isIconColumn && props.textAlign === 'center' ? 0 : token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => (props.width ? `${props.width}%` : '100%'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	textAlign: (props) => (props.textAlign ? `${props.textAlign}` : undefined),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-of-type': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		paddingLeft: (props) =>
			props.isSortable || (props.isIconColumn && props.textAlign === 'center')
				? 0
				: token('space.100', '8px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-of-type': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		paddingRight: (props) =>
			props.isIconColumn && props.textAlign === 'center' ? 0 : token('space.100', '8px'),
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[`&:hover [data-component-selector="${SortIconContainerSelector}"], &:focus [data-component-selector="${SortIconContainerSelector}"]`]:
		{
			opacity: 0.5,
			marginLeft: token('space.050', '4px'),
		},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[`&:hover [data-component-selector="${SortableIconCellContentWrapperSelector}"], &:focus [data-component-selector="${SortableIconCellContentWrapperSelector}"]`]:
		{
			opacity: 0,
		},
});

const HeadCellContainer = componentWithCondition(
	() => isVisualRefreshEnabled() && fg('visual-refresh_drop_2'),
	HeadCellContainerNew,
	HeadCellContainerOld,
);

interface SortHeadCellContainerProps {
	isActive?: boolean;
	width?: number;
	isIconColumn?: boolean;
	isVisualRefresh?: boolean;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SortHeadCellContainerOld = styled.button<SortHeadCellContainerProps>({
	border: 0,
	background: 'none',
	fontWeight: token('font.weight.medium'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSize}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N500),
	boxShadow: 0,
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	padding: (props) =>
		`${token('space.050', '4px')} ${props.isIconColumn && props.isVisualRefresh ? token('space.0', '0px') : token('space.100', '8px')}`,
	borderRadius: '3px',
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	position: (props) => (props.isIconColumn ? 'relative' : 'unset'),

	'&:hover, &:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('elevation.surface.sunken', colors.N20),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SortHeadCellContainerNew = styled.button<SortHeadCellContainerProps>({
	border: 0,
	background: 'none',
	font: token('font.heading.xxsmall'),
	color: token('color.text.subtle'),
	alignItems: 'center',
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	padding: (props) =>
		`${token('space.050', '4px')} ${props.isIconColumn && props.isVisualRefresh ? token('space.0', '0px') : token('space.100', '8px')}`,
	borderRadius: token('border.radius'),
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	position: (props) => (props.isIconColumn ? 'relative' : 'unset'),

	'&:hover, &:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('elevation.surface.sunken', colors.N20),
	},
});

const SortHeadCellContainer = componentWithCondition(
	() => isVisualRefreshEnabled() && fg('visual-refresh_drop_2'),
	SortHeadCellContainerNew,
	SortHeadCellContainerOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContentWrapper = styled.span({
	textTransform: 'capitalize',
});
