import React from 'react';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import { LOAD_START_MARK_KEY } from './common/constants.tsx';
import type { Props } from './ui/types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyComponent = lazy(
	() => import(/* webpackChunkName: "async-project-restore-modal" */ './index'),
);

const Component = (props: Props) => (
	<JSErrorBoundary
		fallback="flag"
		id="bundle-async-project-restore-modal"
		packageName="jira-project-restore-modal"
	>
		<Placeholder
			name="project-restore-modal"
			fallback={<PerformanceMark metricKey={LOAD_START_MARK_KEY} />}
		>
			<LazyComponent {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

export default Component;
