import React, { Component } from 'react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import {
	DocumentTitleHelper,
	DocumentTitleHelperOld,
	DocumentTitleSubscriber,
} from './DocumentTitleContainer.tsx';

type DocumentTitleProps = {
	title: string;
	TitleSubscriber?: typeof DocumentTitleSubscriber; // remove on jira-concurrent-document-title cleanup
};

// eslint-disable-next-line jira/react/no-class-components
class DocumentTitleOld extends Component<DocumentTitleProps> {
	static defaultProps = {
		TitleSubscriber: DocumentTitleSubscriber,
	};

	shouldComponentUpdate(nextProps: DocumentTitleProps) {
		return nextProps.title !== this.props.title;
	}

	render() {
		const { title, TitleSubscriber = DocumentTitleSubscriber } = this.props;
		return (
			<TitleSubscriber>
				{/* @ts-expect-error - TS2339 - Property 'contextArray' does not exist on type 'undefined'. */}
				{({ contextArray }, { setTitle, addDocumentTitle, removeDocumentTitle }) => (
					<DocumentTitleHelperOld
						title={title}
						setTitle={setTitle}
						addDocumentTitle={addDocumentTitle}
						contextArray={contextArray}
						removeDocumentTitle={removeDocumentTitle}
						TitleSubscriber={TitleSubscriber}
					/>
				)}
			</TitleSubscriber>
		);
	}
}

export const DocumentTitle: (props: DocumentTitleProps) => React.ReactNode = componentWithFG(
	'jira-concurrent-document-title',
	DocumentTitleHelper,
	DocumentTitleOld,
);
