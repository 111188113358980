import { Component } from 'react';
import UFOInteractionContext, {
	type UFOInteractionContextType,
} from '@atlaskit/react-ufo/interaction-context';
import { browserMetrics } from '@atlassian/browser-metrics';
import { setMark, setMeasure } from '@atlassian/jira-common-performance/src/marks.tsx';
import { stopLowPriorityEventDelay } from '@atlassian/jira-event-delay-controller/src/index.tsx';
import { PRODUCT_START_MARK } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import {
	setInitialPageLoadTimingFromPerformanceMarks,
	stopInitialPageLoadTimingFromPerformanceMarkStart,
} from '@atlassian/jira-spa-performance-breakdown/src/utils/performance-marks-tools/index.tsx';
import { isAutomaticExposureCollectionEnabled } from '@atlassian/jira-track-all-changes-sampling';
import { TTI_RAF_MARK } from '../../constants.tsx';
import type { BM3Metric, CustomData } from '../types.tsx';

const emitMetrics = (metric: BM3Metric, customData: CustomData, stopTime?: number) => {
	setInitialPageLoadTimingFromPerformanceMarks(
		'batch-js-heritage.eval',
		'jira.heritage_batch_file_eval:start',
		'jira.heritage_batch_file_eval:end',
	);

	if (stopTime !== undefined) {
		metric.stop({
			customData,
			stopTime,
		});
	} else {
		metric.stop({ customData });
	}

	if (isAutomaticExposureCollectionEnabled()) {
		stopLowPriorityEventDelay();
	}
};

// eslint-disable-next-line jira/react/no-class-components
export class StopBrowserMetrics3 extends Component<{
	metric?: BM3Metric;
	customData: CustomData;
	emitOnAnimationFrame?: Boolean;
}> {
	componentDidMount() {
		const { metric, customData, emitOnAnimationFrame } = this.props;

		if (!metric) {
			return;
		}

		const start = browserMetrics.getPageLoadMetric()?.getData()?.start || 0;

		setMark('BROWSER_METRICS_TTI_START', { startTime: start });
		setMark('BROWSER_METRICS_TTI_END');
		setMeasure('BROWSER_METRICS_TTI', 'BROWSER_METRICS_TTI_START', 'BROWSER_METRICS_TTI_END');

		const bm3Data = metric.getData();
		if (emitOnAnimationFrame) {
			const stopTime = performance.now();
			stopInitialPageLoadTimingFromPerformanceMarkStart('product', PRODUCT_START_MARK, true);

			if (bm3Data) {
				this.context?.addApdex({
					key: bm3Data.key,
					startTime: bm3Data.start,
					stopTime,
				});
			}

			if (typeof queueMicrotask !== 'undefined') {
				queueMicrotask(() => {
					setMark('BROWSER_METRICS_TTI_TASK_END');
					setMeasure(
						'BROWSER_METRICS_TTI_TASK',
						'BROWSER_METRICS_TTI_START',
						'BROWSER_METRICS_TTI_TASK_END',
					);
				});
			}

			requestAnimationFrame(() => {
				metric.mark(TTI_RAF_MARK);
				setMark(TTI_RAF_MARK);
				setMeasure('BROWSER_METRICS_TTI_RAF', 'BROWSER_METRICS_TTI_START', TTI_RAF_MARK);
				setTimeout(() => {
					emitMetrics(metric, customData, stopTime);
				}, 500);
			});
		} else {
			if (bm3Data) {
				this.context?.addApdex({
					key: bm3Data.key,
					startTime: bm3Data.start,
					stopTime: performance.now(),
				});
			}

			stopInitialPageLoadTimingFromPerformanceMarkStart('product', PRODUCT_START_MARK, true);
			emitMetrics(metric, customData);
		}
	}

	static contextType = UFOInteractionContext;

	// @ts-expect-error - TS2564: Property 'context' has no initializer and is not definitely assigned in the constructor.
	context: UFOInteractionContextType;

	render() {
		return null;
	}
}
